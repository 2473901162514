<div class="content-container">
    <div class="content-title-group not-found">
      <h1 class="title">Federated Plugins</h1>
      <h2 class="title">Initialize</h2>
      <p>
        Execute the following commands in a terminal window to get started. If you didn't end up with working code at the end of exercise 3, no worries. You can git pull and checkout feature/exercise-3 before you make this new branch.
      </p>
      <pre><code [highlight]="codeBranch" [languages]="['bash']" [lineNumbers]="false"></code></pre>
      <p>Let's create another micro frontend application that will show a series of pricing graph plugins.</p>
      <pre><code [highlight]="codeNewApp" [languages]="['bash']" [lineNumbers]="false"></code></pre>
      <p>Update app.component.html with just a simple h1 tag</p>
      <pre><code [highlight]="codeAppComponentHtml" [languages]="['html']" [lineNumbers]="false"></code></pre>
      <p>From a terminal, execute the following commands to create four new components:</p>
      <pre><code [highlight]="codeCreateComponents" [languages]="['bash']" [lineNumbers]="false"></code></pre>
      <p>Update the respective components with the following html images. Each component should only have a single image. So the graph 1 component will only have reference to a single image graph1.png</p>
      <pre><code [highlight]="codePluginHtml" [languages]="['html']" [lineNumbers]="false"></code></pre>
      <p>Add the following css class to each css file for each component. Redundant, but it's possible that you could decide to have these values be different for each plugin.</p>
      <pre><code [highlight]="codePluginCss" [languages]="['html']" [lineNumbers]="false"></code></pre>
      <p>Add a reference to each plugin in the app.component.html</p>
      <pre><code [highlight]="codePluginReference" [languages]="['html']" [lineNumbers]="false"></code></pre>
      <p>Update the standalone app.component.ts to import the newly created chart components.</p>
      <pre><code [highlight]="codeAppComponentTs" [languages]="['typescript']" [lineNumbers]="true"></code></pre>
      <p>From a terminal, execute the following command to check if the components are displaying properly. They should appear like the image below.</p>
      <pre><code [highlight]="codeRunMFE2" [languages]="['bash']" [lineNumbers]="false"></code></pre>
      <img src="assets/ex4.1.png" class="responsive" />

      <br />
      <h2>Add Module Federation</h2>
      <p>Now that we have the components created, let's get them exposed as remote modules. From a terminal, execute the following command:</p>
      <pre><code [highlight]="codeAddMF" [languages]="['bash']" [lineNumbers]="false"></code></pre>
      <p>Since we just ran the schematic to add module federation to our new mfe2 application, can you guess what the next step is? If you said "update the webpack.config.js", you're right!</p>
      <p>We're going to expose multiple components in this configuration instead of the entire module. Try to update the configuration on your own first before you look at the complete example below. These plugins only need to share @angular/core and @angular/common</p>
      <pre><code [highlight]="codeUpdateWebpack" [languages]="['typescript']" [lineNumbers]="true"></code></pre>
      <p>Let's run the application mfe2 quickly to make sure that everything is still working to this point.</p>
      <pre><code [highlight]="codeRunMFE2" [languages]="['bash']" [lineNumbers]="false"></code></pre>
      <img src="assets/ex4.1.png" class="responsive" />

      <br />
      <h2>Add plugins to the shell</h2>
      <p>Create a "plugins" folder below the "app" folder in the shell project</p>
      <img src="assets/ex4.2.png" class="responsive" />
      <p>Create plugin.ts in the plugins folder and create the plugin type</p>
      <pre><code [highlight]="codePluginType" [languages]="['typescript']" [lineNumbers]="true"></code></pre>
      <p>We are going to need a component that will be able to dynamically render these chart components when they are needed in the UI. Run the following command to create a new proxy component.</p>
      <pre><code [highlight]="codeCreateProxy" [languages]="['bash']" [lineNumbers]="false"></code></pre>
      <p>We'll cover what this component does in a discussion, but set the value of this component to the following:</p>
      <pre><code [highlight]="codeProxyCode" [languages]="['typescript']" [lineNumbers]="true"></code></pre>
      <p>We're going to need a service that provides the list of available components. Execute the following command to create the service:</p>
      <pre><code [highlight]="codeProxyService" [languages]="['bash']" [lineNumbers]="false"></code></pre>
      <p>Set the value of the service to:</p>
      <pre><code [highlight]="codeLookupService" [languages]="['typescript']" [lineNumbers]="true"></code></pre>
      <p>We need to create an interface that will now add these plugins to the UI. Update home.component.ts in the shell project to look like the following:</p>
      <pre><code [highlight]="codeHomeComponent" [languages]="['typescript']" [lineNumbers]="true"></code></pre>
      <p>Now let's update the template for the home component</p>
      <pre><code [highlight]="codeHomeTemplate" [languages]="['html']" [lineNumbers]="true"></code></pre>
      <p>The CLI may have added the PluginProxyComponent improperly in the app.module.ts in the shell project. Make your app.module.ts look like the following after you look at the error displayed in the IDE.</p>
      <pre><code [highlight]="codeAppModule" [languages]="['typescript']" [lineNumbers]="true"></code></pre>
      <h2>Run the application</h2>
      <p>We've spent a lot of time getting this ready. Time to see if this actually runs. Execute the following commands in three different terminals. Make sure the first two micro frontends are running before you start the shell application.</p>
      <pre><code [highlight]="codeRunApps" [languages]="['bash']" [lineNumbers]="false"></code></pre>
      <p>If everything went according to plan, you should see the following on the home page.</p>
      <img src="assets/ex4.3.png" class="responsive" />
      <p>Click on a few of the plugin options and they will get added dynamically to the page.</p>
      <img src="assets/ex4.4.png" class="responsive" />
      <p>One thing you'll notice if you look at the networking tab is that unlike the micro frontend that is loaded via the manifest, you don't see any calls to remoteEntry.js on port 3001 UNTIL you actually click on one of the chart types.</p>
      <img src="assets/ex4.5.png" class="responsive" />
      <p>Once you click on any of the plugins, you see a call to remoteEntry.js on port 3001 followed by the source code required to generate the component that you selected. If you click that chart type again, the source code isn't retrieved again as webpack already knows that it has been loaded into memory.</p>
      <img src="assets/ex4.6.png" class="responsive" />
      <p>Once all the chart types have been clicked, you'll see that you only ever get one call to remoteEntry.js on port 3001 and source code for each exposed component loaded a single time.</p>
      <img src="assets/ex4.7.png" class="responsive" />

      <p>Congratulations! You have working dynamic plugins loaded via module federation. Let's commit these changes.</p>
      <pre><code [highlight]="codeCommitEndOfExercise" [languages]="['bash']" [lineNumbers]="false"></code></pre>

      <h2>Bonus Exercise</h2>
      <p>Add another graph component with a configuration like below AND with an image url of https://www.mfeworkshop.com/assets/graph5.png</p>
      <pre><code [highlight]="codeBonus1" [languages]="['json']" [lineNumbers]="false"></code></pre>

      <h2>Bonus Exercise Part Deux</h2>
      <p>One problem with our simple implementation is that you can add the same chart more than once to the home page. Also, when you navigate to the cryptos page and then come back to home, we lose state of the opened components.</p>
      <p>Using either a shared library or any other solution that you can think of, prevent a user from being able to select the same graph more than once. Additionally, maintain the state of the opened graphs when you navigate back to the home page.</p>

    </div>
  </div>
