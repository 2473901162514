<div class="content-container">
    <div class="content-title-group not-found">
      <h1 class="title">Dynamic Federation</h1>
      <h2 class="title">Initialize</h2>
      <p>
        Execute the following commands in a terminal window to get started. If you didn't end up with working code at the end of exercise 1, no worries. You can git pull and checkout feature/exercise-1 before you make this new branch.
      </p>
      <pre><code [highlight]="codeBranch" [languages]="['bash']" [lineNumbers]="false"></code></pre>
      <p>Create a new folder under shell/src/app called <em>utilities</em></p>
      <img src="assets/ex2.1.png" class="responsive" />
      <p>Then create a new file called <em>remoteConfig.ts</em> in this new utilities folder</p>
      <img src="assets/ex2.2.png" class="responsive" />
      <p>Update remoteConfig.ts to look like the following:</p>
      <pre><code [highlight]="codeRemoteConfig" [languages]="['typescript']" [lineNumbers]="true"></code></pre>
      <p>Create a new file named <em>routes.ts</em> in the utilities folder. Update it to look like the following:</p>
      <pre><code [highlight]="codeRoutes" [languages]="['typescript']" [lineNumbers]="true"></code></pre>
      <p>Update the app.component.ts file in the shell project to look like the following:</p>
      <pre><code [highlight]="codeAppComponent" [languages]="['typescript']" [lineNumbers]="true"></code></pre>
      <p>Let's remove the hardcoded route in the app.routes.ts in the shell project</p>
      <pre><code [highlight]="codeUpdatedRoutes" [languages]="['typescript']" [lineNumbers]="true"></code></pre>
      <p>Replace the cryptos portion of the navigation with the following code in the shell's app.component.html</p>
      <pre><code [highlight]="codeRouteReplace" [languages]="['html']" [lineNumbers]="true"></code></pre>
      <p>Here's the full file for comparison</p>
      <pre><code [highlight]="codeAppComponentHtml" [languages]="['html']" [lineNumbers]="true"></code></pre>
      <p>Finally, let's update our mf.manifest.json to match the new format with the additional metadata.</p>
      <pre><code [highlight]="codeMfManifest" [languages]="['json']" [lineNumbers]="true"></code></pre>
      <h2>Run the application</h2>
      <p>Open Two Terminal windows and execute the following commands. Note: let the mfe1 project complete starting up before starting the shell application.</p>
      <pre><code [highlight]="codeStartApps1" [languages]="['bash']" [lineNumbers]="false"></code></pre>
      <p>The application should look exactly the same. The biggest difference is how this dynamic approach could be used to allow teams to deploy completely new sections of the application without having to make changes to the shell application.</p>
      <img src="assets/ex2.3.png" class="responsive" />
      <p>Congratulations! You have working dynamic module federation. Let's commit these changes.</p>
      <pre><code [highlight]="codeCommitEndOfExercise" [languages]="['bash']" [lineNumbers]="false"></code></pre>

      <h2>Bonus Exercise</h2>
      <p>Let's suppose we wanted to remove the responsibility of managing the remote configuration from the shell application? Which file would we modify?</p>
      <p>Make a change to that file to retrieve the remote configuration from https://www.mfeworkshop.com/assets/mf.manifest.json</p>
      <p>The first person to send a screenshot of this working locally on your machine to Owen wins a prize</p>
    </div>
  </div>
