import { Routes } from '@angular/router';
import { AboutComponent } from './about.component';
import { NotFoundComponent } from './core';
import { ModuleFederationComponent } from './module-federation/module-federation.component';
import { DynamicFederationComponent } from './dynamic-federation/dynamic-federation.component';
import { SharedLibrariesComponent } from './shared-libraries/shared-libraries.component';
import { FederatedPluginsComponent } from './federated-plugins/federated-plugins.component';
import { NxFederationComponent } from './nx-module-federation/nx-federation.component';

export const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'modulefederation' },
  { path: 'modulefederation', component: ModuleFederationComponent },
  { path: 'dynamicfederation', component: DynamicFederationComponent },
  { path: 'sharedlibraries', component: SharedLibrariesComponent },
  { path: 'federatedplugins', component: FederatedPluginsComponent },
  { path: 'nxfederation', component: NxFederationComponent },
  { path: 'about', component: AboutComponent },
  { path: '**', component: NotFoundComponent },
];
