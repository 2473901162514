<div class="content-container">
    <div class="content-title-group not-found">
      <h1 class="title">Shared Libraries</h1>
      <h2 class="title">Initialize</h2>
      <p>
        Execute the following commands in a terminal window to get started. If you didn't end up with working code at the end of exercise 2, no worries. You can git pull and checkout feature/exercise-2 before you make this new branch.
      </p>
      <pre><code [highlight]="codeBranch" [languages]="['bash']" [lineNumbers]="false"></code></pre>
      <p>Let's create a library that will manage our crypto wallet. Execute the following command from a terminal.</p>
      <pre><code [highlight]="codeCreateLibrary" [languages]="['bash']" [lineNumbers]="false"></code></pre>
      <p>In your tsconfig.json in the project's root, adjust the path mapping for wallet-lib</p>
      <pre><code [highlight]="codeUpdateTsConfig" [languages]="['json']" [lineNumbers]="false"></code></pre>
      <p>Restart your IDE in order to make sure that the settings are properly reflected.</p>
      <p>Add the following code to your shell's webpack configuration: sharedMappings: ['wallet-lib'],</p>
      <pre><code [highlight]="codeWebpackConfig" [languages]="['typescript']" [lineNumbers]="true"></code></pre>
      <p>Make corresponding changes to the webpack.config.js in the mfe1 project</p>
      <pre><code [highlight]="codeWebpackMFE1" [languages]="['typescript']" [lineNumbers]="true"></code></pre>

      <h2>Update the wallet library</h2>
      <p>Switch to your wallet-lib project and open the file wallet-lib.service.ts. Adjust it as follows:</p>
      <pre><code [highlight]="codeWalletLibService" [languages]="['typescript']" [lineNumbers]="true"></code></pre>
      <p>Switch to your shell project and open its home.component.ts. Use the shared WalletLibService to display the current wallet balance:</p>
      <pre><code [highlight]="codeHomeComponent" [languages]="['typescript']" [lineNumbers]="true"></code></pre>
      <p>Bind the balance in the home.component.html in the shell</p>
      <pre><code [highlight]="codeHomeHtml" [languages]="['html']" [lineNumbers]="true"></code></pre>
      <p>Switch to your mfe1 project and open its cryptos-search.component.ts. Use the shared service to retrieve the current wallet balance:</p>
      <pre><code [highlight]="codeCryptosSearch" [languages]="['typescript']" [lineNumbers]="true"></code></pre>
      <p>Open this component's template(cryptos-search.component.html) and data bind the property user:</p>
      <pre><code [highlight]="codeCryptosSearchAdds" [languages]="['html']" [lineNumbers]="true"></code></pre>
      <p>Here's the full file.</p>
      <pre><code [highlight]="codeCryptosSearchHtml" [languages]="['html']" [lineNumbers]="true"></code></pre>
      <p>Restart both, the shell and the micro frontend (mfe1). The home page should show a wallet balance of $1,000</p>
      <img src="assets/ex3.1.png" class="responsive" />
      <p>
        In the shell, navigate to the cryptos micro frontend and click on search to show the doge crypto card. If it shows the same balance, the library is shared.</p>
    <img src="assets/ex3.2.png" class="responsive" />
    <p>
        Click on purchase until you get to $500.</p>
        <img src="assets/ex3.3.png" class="responsive" />
        <p>Navigate to the home page and check that the balance is in sync.
        </p>
        <img src="assets/ex3.4.png" class="responsive" />

        <p>Congratulations! You have working shared libraries via module federation. Let's commit these changes.</p>
      <pre><code [highlight]="codeCommitEndOfExercise" [languages]="['bash']" [lineNumbers]="false"></code></pre>




    </div>
  </div>
