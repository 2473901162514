import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { HighlightModule, HIGHLIGHT_OPTIONS } from 'ngx-highlightjs';

import { routes } from './router';
import { AppComponent } from './app.component';
import { AboutComponent } from './about.component';
import { RouterModule } from '@angular/router';
import { declarations } from './core';
import { ModuleFederationComponent } from './module-federation/module-federation.component';
import { DynamicFederationComponent } from './dynamic-federation/dynamic-federation.component';
import { SharedLibrariesComponent } from './shared-libraries/shared-libraries.component';
import { FederatedPluginsComponent } from './federated-plugins/federated-plugins.component';
import { NxFederationComponent } from './nx-module-federation/nx-federation.component';

@NgModule({
  declarations: [
    AppComponent,
    AboutComponent,
    declarations,
    ModuleFederationComponent,
    DynamicFederationComponent,
    SharedLibrariesComponent,
    FederatedPluginsComponent,
    NxFederationComponent,
  ],
  imports: [BrowserModule, HttpClientModule, RouterModule.forRoot(routes), HighlightModule],
  providers: [{
    provide: HIGHLIGHT_OPTIONS,
    useValue: {
      coreLibraryLoader: () => import('highlight.js/lib/core'),
      lineNumbersLoader: () => import('highlightjs-line-numbers.js'), // Optional, only if you want the line numbers
      languages: {
        typescript: () => import('highlight.js/lib/languages/typescript'),
        css: () => import('highlight.js/lib/languages/css'),
        xml: () => import('highlight.js/lib/languages/xml'), 
        JSON: () => import('highlight.js/lib/languages/json'),
        javascript: () => import('highlight.js/lib/languages/javascript'),
        bash: () => import('highlight.js/lib/languages/bash')
      }
    }
  }],
  bootstrap: [AppComponent],
})
export class AppModule {}
