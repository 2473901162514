import { Component } from '@angular/core';

@Component({
  selector: 'app-federated-plugins',
  templateUrl: './federated-plugins.component.html',
  styleUrls: ['./federated-plugins.component.scss']
})
export class FederatedPluginsComponent {
  codeBranch = `git checkout -b "feature/exercise-4"`;

  codeNewApp = `nx g application mfe2 --prefix=mfe2 --routing=false --style=css`;

  codeAppComponentHtml = `// mfe2/src/app/app.component.html
  
  <h1>mfe2</h1>`;

  codeAppComponentTs = `// mfe2/src/app/app.component.ts
  import { Component } from '@angular/core';
  import { Chart1Component } from './chart1/chart1.component';
  import { Chart2Component } from './chart2/chart2.component';
  import { Chart3Component } from './chart3/chart3.component';
  import { Chart4Component } from './chart4/chart4.component';

  @Component({  
    selector: 'mfe2-root',
    standalone: true,
    imports: [Chart1Component, Chart2Component, Chart3Component, Chart4Component],
    templateUrl: './app.component.html',
    styleUrl: './app.component.css'
  })
  export class AppComponent {
    title = 'mfe2';
  }

  `;

  codeCreateComponents = `  nx g component chart1 --project=mfe2 --module=app --skip-tests --selector=mfe2-chart1
  nx g component chart2 --project=mfe2 --module=app --skip-tests --selector=mfe2-chart2
  nx g component chart3 --project=mfe2 --module=app --skip-tests --selector=mfe2-chart3
  nx g component chart4 --project=mfe2 --module=app --skip-tests --selector=mfe2-chart4`;

  codePluginHtml = `  <img src="https://www.mfeworkshop.com/assets/graph1.png" class="widget"/> 
  <img src="https://www.mfeworkshop.com/assets/graph2.png" class="widget"/>
  <img src="https://www.mfeworkshop.com/assets/graph3.png" class="widget"/>
  <img src="https://www.mfeworkshop.com/assets/graph4.png" class="widget"/>`;

  codePluginCss = `.widget { height: 200px; width: 400px; max-width: 100%; }`;

  codePluginReference = `// mfe2/src/app/app.component.html
  
  <h1>mfe2</h1> 
 
  <mfe2-chart1></mfe2-chart1>
   
  <mfe2-chart2></mfe2-chart2>
   
  <mfe2-chart3></mfe2-chart3>
   
  <mfe2-chart4></mfe2-chart4>
  
  `;

  codeRunMFE2 = `nx serve mfe2 --port=3001 -o`;

  codeAddMF = `nx g @angular-architects/module-federation:init --project mfe2 --port 3001 --type remote`;

  codeUpdateWebpack = `// mfe2/webpack.config.js
  
  const {
    share,
    withModuleFederationPlugin,
  } = require("@angular-architects/module-federation/webpack");
  module.exports = withModuleFederationPlugin({
    name: "mfe2",
    exposes: {
      "./Chart1": "./mfe2/src/app/chart1/chart1.component.ts",
      "./Chart2": "./mfe2/src/app/chart2/chart2.component.ts",
      "./Chart3": "./mfe2/src/app/chart3/chart3.component.ts",
      "./Chart4": "./mfe2/src/app/chart4/chart4.component.ts",
    },
    shared: share({
      "@angular/core": {
        singleton: true,
        strictVersion: true,
        requiredVersion: "auto",
      },
      "@angular/common": {
        singleton: true,
        strictVersion: true,
        requiredVersion: "auto",
      },
    }),
  });  
  `;

  codePluginType = `// shell/src/app/plugins/plugin.ts
  
  import { LoadRemoteModuleOptions } from '@angular-architects/module-federation';
  export type PluginOptions = LoadRemoteModuleOptions & {
      displayName: string;
      componentName: string;
  };
  `;

  codeCreateProxy = `nx g component plugins/plugin-proxy --selector=plugin-proxy --module=app --project=shell --inline-template=true --flat=true --skip-tests=true --standalone=false
  
  // Use the Angular Schema to create the component when prompted`;

  codeProxyCode = `// shell/src/app/plugins/plugin-proxy.component.ts
  
  import {Component,Input,OnChanges,ViewChild,ViewContainerRef} from '@angular/core';
  import {loadRemoteModule} from '@angular-architects/module-federation';
  import {PluginOptions} from'./plugin';
  @Component({
    standalone: false,
    selector: 'plugin-proxy',
    template: \`
        <ng-container #placeHolder></ng-container>
    \`
  })
  export class PluginProxyComponent implements OnChanges{
    @ViewChild('placeHolder',{read: ViewContainerRef,static: true})
    viewContainer: ViewContainerRef;
    constructor(){}
    @Input() options: PluginOptions;
    async ngOnChanges(){
        this.viewContainer.clear();
        const Component=await loadRemoteModule(this.options)
            .then(m=>m[this.options.componentName]);
        this.viewContainer.createComponent(Component);
    }
  }

  `;

  codeProxyService = `nx g service plugins/lookup --flat=true --project=shell --skip-tests=true`;

  codeLookupService = `// shell/src/app/plugins/lookup.service.ts
  
  import { Injectable } from '@angular/core';
  import { PluginOptions } from './plugin';
  @Injectable({ providedIn: 'root' })
  export class LookupService {
    lookup(): Promise<PluginOptions[]> {
        return Promise.resolve([
            {
                type: 'module',
                remoteEntry: 'http://localhost:3001/remoteEntry.js',
                exposedModule: './Chart1',
                displayName: 'Pricing Trends',
                componentName: 'Chart1Component'
            },
            {
              type: 'module',
              remoteEntry: 'http://localhost:3001/remoteEntry.js',
              exposedModule: './Chart2',
              displayName: 'Watch List',
              componentName: 'Chart2Component'
          },
          {
            type: 'module',
            remoteEntry: 'http://localhost:3001/remoteEntry.js',
            exposedModule: './Chart3',
            displayName: 'Current Value',
            componentName: 'Chart3Component'
        },
        {
          type: 'module',
          remoteEntry: 'http://localhost:3001/remoteEntry.js',
          exposedModule: './Chart4',
          displayName: 'Bitcoin Tracker',
          componentName: 'Chart4Component'
      }
        ] as PluginOptions[]);
    }
  }
  `;

  codeHomeComponent = `// shell/src/app/home/home.component.ts
  
  import { Component, OnInit } from '@angular/core';
  import { WalletLibService } from 'wallet-lib';
  import { LookupService } from '../plugins/lookup.service';
  import { PluginOptions } from '../plugins/plugin';
  @Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
  })
  export class HomeComponent implements OnInit {
    balance = 0;
    plugins: PluginOptions[] = [];
    graphs: PluginOptions[] = [];
    constructor(private walletService: WalletLibService, private lookupService: LookupService) {
    this.balance = this.walletService.balance;
    }
    async ngOnInit(): Promise<void> {
    this.plugins = await this.lookupService.lookup();
    }
    handleClick(graph: PluginOptions): void {
    this.graphs.push(graph);
    }
  }
  `;

  codeHomeTemplate = `// shell/src/app/home/home.component.html
  
  <h1>Welcome!</h1>
  <h1>Wallet Balance: {{ balance | currency}}</h1>
  <mat-selection-list #charts [multiple]="false">
    <mat-list-option *ngFor="let p of plugins" [value]="p" (click)="handleClick(p)">
    {{p.displayName}}
    </mat-list-option>
  </mat-selection-list>
  <ng-container *ngFor="let p of graphs">
    <plugin-proxy [options]="p"></plugin-proxy>
  </ng-container>`;

  codeAppModule = `// shell/src/app/app.module.ts
  
  import { NgModule } from '@angular/core';
  import { MatButtonModule } from '@angular/material/button';
  import { MatIconModule } from '@angular/material/icon';
  import { MatListModule } from '@angular/material/list';
  import { MatSidenavModule } from '@angular/material/sidenav';
  import { MatToolbarModule } from '@angular/material/toolbar';
  import { BrowserModule } from '@angular/platform-browser';
  import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
  import { RouterModule } from '@angular/router';
  import { AppComponent } from './app.component';
  import { APP_ROUTES } from './app.routes';
  import { HomeComponent } from './home/home.component';
  import { NotFoundComponent } from './not-found/not-found.component';
  import { PluginProxyComponent } from './plugins/plugin-proxy.component';
  @NgModule({
    imports: [
    BrowserModule,
    RouterModule.forRoot(APP_ROUTES),
    BrowserAnimationsModule,
    MatToolbarModule,
    MatSidenavModule,
    MatListModule,
    MatButtonModule,
    MatIconModule,
    ],
    declarations: [AppComponent, HomeComponent, NotFoundComponent, PluginProxyComponent],
    providers: [],
    bootstrap: [AppComponent],
  })
  export class AppModule {}
  `;

  codeRunApps = `
  npm run start:mfe1
  npm run start:mfe2
  npm run start:shell
  `;

  codeCommitEndOfExercise = `git add *
git commit -m "feat: working dynamic plugins"
  `;

  codeBonus1 = `{
    type: 'module',
    remoteEntry: 'http://localhost:3001/remoteEntry.js',
    exposedModule: './Chart5',
    displayName: 'Bonus Report',
    componentName: 'Chart5Component'
}`;
}
