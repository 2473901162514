import { Component } from '@angular/core';

@Component({
  selector: 'app-dynamic-federation',
  templateUrl: './dynamic-federation.component.html',
  styleUrls: ['./dynamic-federation.component.scss']
})
export class DynamicFederationComponent {

  codeBranch = `git checkout -b "feature/exercise-2"`;

  codeRemoteConfig = `// shell/src/app/utilities/remoteConfig.ts
  
  import { Manifest, RemoteConfig } from '@angular-architects/module-federation';

  export type CustomRemoteConfig = RemoteConfig & {
    exposedModule: string;
    displayName: string;
    routePath: string;
    ngModuleName: string;
  };

  export type CustomManifest = Manifest<CustomRemoteConfig>;  
`;

  codeRoutes = `// shell/src/app/utilities/routes.ts
  
  import { loadRemoteModule } from '@angular-architects/module-federation';
  import { Routes } from '@angular/router';
  import { APP_ROUTES } from '../app.routes';
  import { CustomManifest } from './remoteConfig';
  export function buildRoutes(options: CustomManifest): Routes {
    const lazyRoutes: Routes = Object.keys(options).map((key) => {
    const entry = options[key];
    return {
      path: entry.routePath,
      loadChildren: () =>
        loadRemoteModule({
          type: 'manifest',
            remoteName: key,
            exposedModule: entry.exposedModule,
        }).then((m) => m[entry.ngModuleName]),
    };
    });
    return [ ...lazyRoutes, ...APP_ROUTES];
  }
  `;

  codeAppComponent = `// shell/src/app/app.component.ts
  
  import { getManifest } from '@angular-architects/module-federation';
  import { Component, OnInit } from '@angular/core';
  import { Router } from '@angular/router';
  import { CustomManifest, CustomRemoteConfig } from './utilities/remoteConfig';
  import { buildRoutes } from './utilities/routes';
  @Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
  })
  export class AppComponent implements OnInit {
    title = 'shell';
    remotes: CustomRemoteConfig[] = [];
    constructor(private router: Router) {}
    async ngOnInit(): Promise<void> {
    const manifest = getManifest<CustomManifest>();
    const routes = buildRoutes(manifest);
    this.router.resetConfig(routes);
    this.remotes = Object.values(manifest);
    }
  }
  `;

  codeUpdatedRoutes = `  // shell/src/app/app/app.routes.ts
  
  import { Routes } from '@angular/router';
  import { HomeComponent } from './home/home.component';
  import { NotFoundComponent } from './not-found/not-found.component';
  
  export const APP_ROUTES: Routes = [
    {
      path: '',
      component: HomeComponent,
      pathMatch: 'full',
    },
    {
      path: '**',
      component: NotFoundComponent,
    },
  ];
  `;

  codeRouteReplace = `  <ng-container *ngFor="let remote of remotes">
    <a mat-list-item (click)="sidenav.toggle()" [routerLink]="remote.routePath">{{
      remote.displayName
    }}</a>
  </ng-container>`;

    codeAppComponentHtml = `  // shell/app/src/app.component.html 
    
    <mat-toolbar color="primary">
    <mat-toolbar-row>
      <button mat-icon-button (click)="sidenav.toggle()">
        <mat-icon>menu</mat-icon>
      </button>
      <span class="menu-spacer"><img src="assets/ng-fake-coin-xl.png" height="60px" class="coin"></span>

    </mat-toolbar-row>
  </mat-toolbar>


  <mat-sidenav-container>
    <mat-sidenav #sidenav mode="side">

      <mat-nav-list>
        <a mat-list-item (click)="sidenav.toggle()" [routerLink]="'/'"> Home </a>
        <ng-container *ngFor="let remote of remotes">
          <a mat-list-item (click)="sidenav.toggle()" [routerLink]="remote.routePath">{{
            remote.displayName
          }}</a>
        </ng-container>
        <a mat-list-item (click)="sidenav.toggle()" mat-list-item>Close </a>

      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content>
      <div class='content-wrapper'>
        <div class='content-spacer'>
        <router-outlet></router-outlet>
        </div>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>

`;

codeStartApps1 = `
    npm run start:mfe1
    
    # Wait for the above command to complete then execute this command in another terminal
    
    npm run start:shell`;

  codeMfManifest = `  // shell/src/assets/mf.manifest.json

  {
    "mfe1": {
    "remoteEntry": "http://localhost:3000/remoteEntry.js",
    "exposedModule": "./Module",
    "displayName": "Buy Crypto",
    "routePath": "cryptos",
    "ngModuleName": "CryptosModule"
    }
  }
`;

codeCommitEndOfExercise = `git add *
git commit -m "feat: working dynamic federation"
  `;

}
