import { Component } from '@angular/core';

@Component({
  selector: 'app-about',
  template: `
    <div class="content-container">
      <div class="content-title-group not-found">
        <h2 class="title">Module Federation Exercises</h2>
        <p>
        These are the workshop exercise for ng-conf module federation 2024.
        </p>
        <br />
        <h2 class="title">Resources</h2>
        <ul>
          <li>
            <a href="https://github.com/owenmecham/mod-fed-2024.git">
              Code in GitHub
            </a>
          </li>
        </ul>
      </div>
    </div>
  `,
})
export class AboutComponent {}
