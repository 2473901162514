<div class="content-container">
    <div class="content-title-group not-found">
      <h1 class="title">Module Federation</h1>
      <h2 class="title">Getting Started</h2>
      <p>
        Execute the following commands in a terminal window to get started. &#123;pickname&#125; should be replaced with whatever you want to name your local repo or leave it blank to default to mod-fed-2024
      </p>
      <pre><code [highlight]="code" [languages]="['bash']" [lineNumbers]="false"></code></pre>
      <p>Let's create a new branch for this exercise and make sure everything works</p>
      <pre><code [highlight]="code2" [languages]="['bash']" [lineNumbers]="false"></code></pre>
      <p>After running the application, you should see the following in the browser</p>
      <img src = "assets/ex1.1.png" class="responsive"/>
      <p>Click on cryptos. See that the market is closed. Let's look at the code in app.routes.ts</p>
      
      <pre><code [highlight]="codeAppRoutesTs1" [languages]="['typescript']" [lineNumbers]="true"></code></pre>

      <h2>Add Module Federation to the Shell</h2>
      <p>We haven't defined an actual route for cryptos at this point, so our NotFoundComponent is what is being displayed when we click on cryptos.</p>
      <p>Let's set up the application for module federation using a manifest file to define our remote modules.</p>
      <p>The other options we could use when setting up module federation include <em>host</em> and <em>remote</em>. We will use <em>remote</em> later on in the tutorial. <em>host</em> is very similar to <em>dynamic-host</em> but you have to statically define the remote modules.</p>
      <p>You can read more about these types <a href="https://github.com/angular-architects/module-federation-plugin/blob/main/libs/mf/README.md#-version-14-use-the---type-switch-to-get-the-new-streamlined-configuration">in the documentation</a>.</p>
      
      <pre><code [highlight]="codeAddModuleFederation" [languages]="['bash']" [lineNumbers]="false"></code></pre>
      <p>You should see the following in the terminal after executing the command:</p>
      <img src="assets/ex1.2.png" class="responsive" />
      <p>Let's take a look at the files that it created.</p>
      <p>In project.json, we see that ngx-build-plus is now taking a role in building and serving our application. </p>
      <img src="assets/ex1.3.png" class="responsive" />
      <p>In project.json, we now have this new option called customWebpackConfig. Module Federation is enabled via webpack. This custom configuration file will extend our current webpack configuration and add the additional information required to configure module federation. </p>
      <img src="assets/customWebpack.png" class="responsive" />
      <p>Let's look at the webpack configuration created for the shell. By default, the shareAll function will do exactly what it says. Share all dependencies from the shell with any remote modules that we define in the future.</p>
      <p>The combination of singleton: true and strictversion: true will cause webpack to throw an error when the host and the remote need incompatible versions of a shared dependency. If you want to roll the dice and hope that things work without throwing a runtime error, you could either remove the strictversion property or set it to false. In that case, webpack will throw a warning in the console.</p>
      <p>Setting requiredVersion to 'auto' will lookup the dependency version from your project's package.json.</p>
      <pre><code [highlight]="codeShellOriginalWebpackConfig" [languages]="['javascript']" [lineNumbers]="true"></code></pre>
      <p>We now have a new file named bootstrap.ts. This looks very similar to what we would expect to see in our main.ts file. The purpose for this file is to perform the actual bootstrapping of the application and separate it from the original process in main.ts. We'll talk about why in a second.</p>
      <pre><code [highlight]="codeBootstrapTs" [languages]="['typescript']" [lineNumbers]="true"></code></pre>
      <p>Module Federation needs to inject itself into the bootstrapping process of the application to be able to identify the shared dependencies that should be exposed by the shell AND to identify the dependencies that are required by any remote modules. To do this, the main.ts no longer simply bootstraps our application, but it triggers a process that initializes the app and performs the dependency analysis.</p>
      <pre><code [highlight]="codeMainTs" [languages]="['typescript']" [lineNumbers]="true"></code></pre>
      <p>Finally, the mf.manifest.json contains a list of remote modules that the host or our shell application will include and make available for federation.</p>
      <pre><code [highlight]="codeOriginalManifest" [languages]="['json']" [lineNumbers]="false"></code></pre>
      <p>Commit your changes to the branch</p>
      <pre><code [highlight]="codeCommitExercise1" [languages]="['bash']" [lineNumbers]="false"></code></pre>

      <h2>Add Module Federation to a remote</h2>
      <p>We have a project in the repo called mfe1. Let's set this up as a remote for module federation. Run the following command in a terminal:</p>
      <pre><code [highlight]="codeAddMFToRemote" [languages]="['bash']" [lineNumbers]="false"></code></pre>
      <p>Let's look at some of the files that were generated.</p>
      <pre><code [highlight]="codeWebpackConfigRemoteInitial" [languages]="['typescript']" [lineNumbers]="true"></code></pre>
      <p>Commit the changes</p>
      <pre><code [highlight]="codeCommitRemoteMFE" [languages]="['bash']" [lineNumbers]="false"></code></pre>
      <p>Let's make some changes to the webpack config for the remote to share only specific dependencies and to expose our cryptos module</p>
      <pre><code [highlight]="codeNewRemoteConfig" [languages]="['typescript']" [lineNumbers]="true"></code></pre>
      <p>Let's make correlating changes in the host webpack config and talk about some real world issues that come up when we have multiple teams working on a large module federation project.</p>
      <pre><code [highlight]="codeNewHostConfig" [languages]="['typescript']" [lineNumbers]="true"></code></pre>
      <p>Update our mf.manifest.json to point to port 3000 instead of 4200</p>
      <pre><code [highlight]="codeNewManifest" [languages]="['json']" [lineNumbers]="false"></code></pre>
      <p>Replace the commented out route in projects/shell/src/app/app.routes.ts to configure the route to the federated cryptos module.</p>
      <pre><code [highlight]="codeCryptosRoute" [languages]="['typescript']" [lineNumbers]="false"></code></pre>

      <!-- Maybe add the entire file here -->

      <h2>Run the application</h2>
      <p>Open Two Terminal windows and execute the following commands. Note: let the mfe1 project complete starting up before starting the shell application.</p>
      <pre><code [highlight]="codeStartApps1" [languages]="['bash']" [lineNumbers]="false"></code></pre>
      <p>If everything works as expected, you should see the following when you click on Cryptos in the menu</p>
      <img src="assets/ex1.5.png" class="responsive" />
      <p>Clicking on Search will show the fake doge card</p>
      <img src="assets/ex1.6.png" class="responsive" />
      <p>If we look at the network tab when the shell loads, we can see module federation in action. Before the dependencies load, you can see that the shell makes a call to port 3000 to retrieve the remoteEntry.js file from the remote micro frontend. After webpack determines the proper versions of the dependencies to load, it actually loads the dependencies.</p>
      <img src="assets/ex1.7.png" class="responsive" />
      <p>Once we click on the cryptos link in the menu, the application will load the remaining dependencies for the micro frontend that haven't been loaded already that weren't needed by the shell application. You can see we load a few more angular material dependencies BEFORE we actually load the code for our remote micro frontend.</p>
      <img src="assets/ex1.8.png" class="responsive" />
      <p>Navigating back to the home page and then clicking on cryptos again will not reload the dependencies or the micro frontend remote code again since it's already in memory.</p>
      <p>Congratulations! You have your first working module federation application. Let's commit these changes.</p>
      <pre><code [highlight]="codeCommitEndOfExercise" [languages]="['bash']" [lineNumbers]="false"></code></pre>
    </div>
  </div>
