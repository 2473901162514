<div class="content-container">
    <div class="content-title-group not-found">
      <h1 class="title">NX Federation</h1>
      <h2 class="title">Installation</h2>
      <p>
        Execute the following commands in a terminal window to get started. 
      </p>
      <pre><code [highlight]="nx1" [languages]="['bash']" [lineNumbers]="false"></code></pre>
      <p>Let's create a brand new Nx Workspace. We will create the host and the first remote application at the same time.</p>
      <pre><code [highlight]="nx2" [languages]="['bash']" [lineNumbers]="false"></code></pre>
      <p>Add Angular Material.</p>
      <pre><code [highlight]="nx3" [languages]="['bash']" [lineNumbers]="false"></code></pre>
      <p>Let's grab the chart images from the plugin exercise from the previous exercise. Copy them into the shell/src/app/assets folder.</p>
      <p>Let's create a new component for our home page. Execute the following command from a terminal window, then change NxWelcomeComponent to be our new HomeComponent in app.routes.ts</p>
      <pre><code [highlight]="nx4" [languages]="['bash']" [lineNumbers]="false"></code></pre>
      <p>Test things out so far.</p>
      <pre><code [highlight]="nx5" [languages]="['bash']" [lineNumbers]="false"></code></pre>
      <h2 class="title">Build Out Shell Component</h2>
      <p>Create a new component to represent not-found routes</p>
      <pre><code [highlight]="nx6" [languages]="['bash']" [lineNumbers]="false"></code></pre>
      <p>Set the content of the not-found component to indicate that the market is closed.</p>
      <pre><code [highlight]="nx7" [languages]="['html']" [lineNumbers]="true"></code></pre>
      <p>Update the scss to use angular material.</p>
      <pre><code [highlight]="nx8" [languages]="['css']" [lineNumbers]="true"></code></pre>
      <p>Add provideAnimations() to app.config.ts</p>
      <pre><code [highlight]="nx9" [languages]="['typescript']" [lineNumbers]="true"></code></pre>
      <p>Update index.html</p>
      <pre><code [highlight]="nx10" [languages]="['typescript']" [lineNumbers]="true"></code></pre>
      <p>Update app.component.scss</p>
      <pre><code [highlight]="nx11" [languages]="['typescript']" [lineNumbers]="true"></code></pre>

      <h2 class="title">Create the cryptos component</h2>
      <p>Execute the following command to create a new component:</p>
      <pre><code [highlight]="nx12" [languages]="['bash']" [lineNumbers]="false"></code></pre>
      <p>Update cryptos.component to be</p>
      <pre><code [highlight]="nx13" [languages]="['typescript']" [lineNumbers]="true"></code></pre>
      <p>Test things out so far.</p>
      <pre><code [highlight]="nx14" [languages]="['bash']" [lineNumbers]="false"></code></pre>
      <p>Update the cryptos.component</p>
      <pre><code [highlight]="nx15" [languages]="['typescript']" [lineNumbers]="true"></code></pre>

      <h2 class="title">Shared Libraries</h2>
      <p>
        Use the NX Generator extension within VS Code to create a new library called <em>wallet</em>.
      </p>
      <pre><code [highlight]="nx16" [languages]="['bash']" [lineNumbers]="false"></code></pre>
      <p>
        Create a new service in the wallet library. Make the service look like:
      </p>
      <pre><code [highlight]="nx17" [languages]="['typescript']" [lineNumbers]="true"></code></pre>
      <p>Switch to your shell project and open its home.component.ts. Use the shared WalletService to display the current wallet balance:</p>
      <pre><code [highlight]="nx18" [languages]="['typescript']" [lineNumbers]="true"></code></pre>
      <p>Update the cryptos.component to use the wallet service.</p>
      <pre><code [highlight]="nx19" [languages]="['typescript']" [lineNumbers]="true"></code></pre>
      <p>
        Run the applications to see the wallet balance in the home and cryptos components.
      </p>
      <pre><code [highlight]="nx14" [languages]="['bash']" [lineNumbers]="false"></code></pre>

      <h2 class="title">Plugins with Nx</h2>
      <p>
        Create a new remote application by executing the following command from a terminal:
      </p>
      <pre><code [highlight]="nx20" [languages]="['bash']" [lineNumbers]="false"></code></pre>
      <p>
        Update nx-welcome.component.ts template to:
      </p>
      <pre><code [highlight]="nx21" [languages]="['typescript']" [lineNumbers]="true"></code></pre>
      <p>
        Use the Nx Generator to create 4 Chart components in the plugin project with the following pattern:<br/>location apps/reports/src/app/chart-1/chart-1 and skip tests.
      </p>
      <p>Update the component css for each component to be:</p>
      <pre><code [highlight]="nx22" [languages]="['typescript']" [lineNumbers]="true"></code></pre>
      <p>Update the html for each component to match the following pattern:</p>
      <pre><code [highlight]="nx23" [languages]="['typescript']" [lineNumbers]="true"></code></pre>
      <p>Update the nx-welcome.component.ts to look like the following to include a reference to each of the chart components.</p>
      <pre><code [highlight]="nx24" [languages]="['typescript']" [lineNumbers]="true"></code></pre>
      <p>Test out the report components</p>
      <pre><code [highlight]="nx25" [languages]="['bash']" [lineNumbers]="false"></code></pre>
      <p>Need to update the module federation config.</p>
      <pre><code [highlight]="nx26" [languages]="['typescript']" [lineNumbers]="true"></code></pre>
      <p>Install the packages required to use the helper functions</p>
      <pre><code [highlight]="nx27" [languages]="['bash']" [lineNumbers]="false"></code></pre>
      <p>Create a "plugins" folder below the "app" folder</p>
      <p>Create plugin.ts and create the plugin type</p>
      <pre><code [highlight]="nx28" [languages]="['typescript']" [lineNumbers]="true"></code></pre>
      <p>Use nx to generate an angular component with name apps/shell/src/app/plugins/plugin-proxy
        
        <br/><br/>skipTests
        <br/>inlineTemplate
        <br/>inlineCss
        
        <br/><br/>Set component value to
        </p>
        <pre><code [highlight]="nx29" [languages]="['typescript']" [lineNumbers]="true"></code></pre>
        <p>Use nx to generate an angular service with the Angular Schematic named <em>Lookup</em>
        
          <br/><br/>skipTests
          <br/>flag
          
          <br/><br/>Make the service look like the following:
          </p>
          <pre><code [highlight]="nx30" [languages]="['typescript']" [lineNumbers]="true"></code></pre>

          <p>Let's see this all come together from scratch:</p>
          <pre><code [highlight]="nx31" [languages]="['bash']" [lineNumbers]="false"></code></pre>
    </div>
  </div>
