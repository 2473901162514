import { Component } from '@angular/core';

@Component({
  selector: 'app-nav',
  template: `
    <nav class="menu">
      <p class="menu-label">Exercises</p>
      <ul class="menu-list">
      <a routerLink="/modulefederation" routerLinkActive="router-link-active">
          <span>Module Federation</span>
        </a>
        <a routerLink="/dynamicfederation" routerLinkActive="router-link-active">
          <span>Dynamic Federation</span>
        </a>
        <a routerLink="/sharedlibraries" routerLinkActive="router-link-active">
          <span>Shared Libraries</span>
        </a>
        <a routerLink="/federatedplugins" routerLinkActive="router-link-active">
          <span>Federated Plugins</span>
        </a>
        <a routerLink="/nxfederation" routerLinkActive="router-link-active">
          <span>Nx Federation</span>
        </a>
        <a routerLink="/about" routerLinkActive="router-link-active">
          <span>About</span>
        </a>
      </ul>
    </nav>
  `,
})
export class NavComponent {}
