import { Component, OnInit } from '@angular/core';
import { HighlightLoader } from 'ngx-highlightjs';


@Component({
  selector: 'app-module-federation',
  templateUrl: './module-federation.component.html',
  styleUrls: ['./module-federation.component.scss']
})
export class ModuleFederationComponent implements OnInit {


  code = `# In a terminal, clone the workshop repo
# Replace {pickname} with whatever you'd like to name the repo

git clone https://github.com/owenmecham/mod-fed-2024.git {pickname}

# Switch to the new repo directory you just created
cd {pickname}

# Install the dependencies
npm i

# Launch VS Code or open the repo in the editor of your choice
code .
  `;

  code2 = `# Create a new branch for exercise 1
git checkout -b "feature/exercise-1"

# Let's make sure the application runs
npm run start
  
  `;

  codeAppRoutesTs1 = `  // projects/shell/src/app/app.routes.ts
  
  import { Routes } from '@angular/router';
  import { HomeComponent } from './home/home.component';
  import { NotFoundComponent } from './not-found/not-found.component';
  
  export const APP_ROUTES: Routes = [
    {
      path: '',
      component: HomeComponent,
      pathMatch: 'full',
    },
  
    // {
    //   path: 'cryptos',
    //   loadChildren: () => import('mfe1/Module').then(m => m.CryptosModule)
    // },
  
    {
      path: '**',
      component: NotFoundComponent,
    },
  ];`;

  codeAddModuleFederation = `# Command to add module federation to our shell project
# --port 5001 defines the port where the shell project will run in our application
# --type dynamic-host sets up module federation with the manifest file

npm install @angular-architects/module-federation@17.0.8
nx g @angular-architects/module-federation:init --project shell --port 5001 --type dynamic-host
`;


  codeShellOriginalWebpackConfig = `  // shell/webpack.config.js
  
  const { shareAll, withModuleFederationPlugin } = require('@angular-architects/module-federation/webpack');

  module.exports = withModuleFederationPlugin({
  
    // This default configuration will share ALL of the dependencies in the shell project with any other modules
    shared: {
      ...shareAll({ singleton: true, strictVersion: true, requiredVersion: 'auto' }),
    },
  
  });
  `;

  codeBootstrapTs = ` // shell/src/bootstrap.ts
  
  import { AppModule } from './app/app.module';
  import { environment } from './environments/environment';
  import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
  import { enableProdMode } from '@angular/core';
  
  if (environment.production) {
    enableProdMode();
  }
  
  platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.error(err));
    `;

  codeMainTs = `  // shell/src/main.ts
  
  import { initFederation } from '@angular-architects/module-federation';

  initFederation('/assets/mf.manifest.json')
  .catch(err => console.error(err))
  .then(_ => import('./bootstrap'))
  .catch(err => console.error(err));
`;

  codeOriginalManifest = `// shell/src/assets/mf.manifest.json
  
{
    "mfe1": "http://localhost:4200/remoteEntry.js"
}`;

  codeCommitExercise1 = `git add *
git commit -m "feat: added mf to shell"`;

  codeAddMFToRemote = `nx g @angular-architects/module-federation:init --project mfe1 --port 3000 --type remote`;

  codeWebpackConfigRemoteInitial = `// projects/mfe1/webpack.config.js
  
  const { shareAll, withModuleFederationPlugin } = require('@angular-architects/module-federation/webpack');

  module.exports = withModuleFederationPlugin({
  
    name: 'mfe1',
  
    // This is where we define the components or modules that we want to share as micro frontends 
    exposes: {
      './Component': './projects/mfe1/src/app/app.component.ts',
    },
  
    // This is where we define the dependencies of this remote module
    shared: {
      ...shareAll({ singleton: true, strictVersion: true, requiredVersion: 'auto' }),
    },
  
  });
  `;

  codeCommitRemoteMFE = `git add *
git commit -m "feat: added module fed to mfe1"
  `;

  codeNewRemoteConfig = `// projects/mfe1/webpack.config.js
  
  const {
    share,
    withModuleFederationPlugin,
  } = require("@angular-architects/module-federation/webpack");
  module.exports = withModuleFederationPlugin({
    name: "mfe1",
    exposes: {
      "./Module": "./projects/mfe1/src/app/cryptos/cryptos.module.ts",
    },
    shared: share({
      "@angular/core": {
        singleton: true,
        strictVersion: true,
        requiredVersion: "auto",
      },
      "@angular/common": {
        singleton: true,
        strictVersion: true,
        requiredVersion: "auto",
      },
      "@angular/router": {
        singleton: true,
        strictVersion: true,
        requiredVersion: "auto",
      },
      "@angular/material": {
        singleton: true,
        strictVersion: true,
        requiredVersion: "auto",
      },
    }),
  });
  `;

  codeNewHostConfig = `   // projects/shell/webpack.config.js
  
  const {
    share,
    withModuleFederationPlugin,
  } = require("@angular-architects/module-federation/webpack");
  module.exports = withModuleFederationPlugin({
    shared: share({
    "@angular/core": {
      singleton: true,
        strictVersion: true,
        requiredVersion: "auto",
    },
    "@angular/common": {
      singleton: true,
        strictVersion: true,
        requiredVersion: "auto",
    },
    "@angular/router": {
      singleton: true,
        strictVersion: true,
        requiredVersion: "auto",
    },
    "@angular/material": {
      singleton: true,
        strictVersion: true,
        requiredVersion: "auto",
    },
    }),
  });`;


  codeNewManifest = `// projects/shell/src/assets/mf.manifest.json
  
  {
    "mfe1": "http://localhost:3000/remoteEntry.js"
  }`;

  codeCryptosRoute = `// Be sure to add the import to loadRemoteModule at the top imports section of the file
  import {loadRemoteModule} from '@angular-architects/module-federation';

  ...

  {
    path: 'cryptos',
    loadChildren: () => loadRemoteModule({ type: 'manifest', remoteName: 'mfe1', exposedModule: './Module'}).then(m => m.CryptosModule)
  },`;


    codeStartApps1 = `
  npm run start:mfe1
    
  # Wait for the above command to complete then execute this command in another terminal
    
  npm run start:shell`;

    codeCommitEndOfExercise = `git add *
git commit -m "feat: working module federation"
  `;

  constructor(private hljsLoader: HighlightLoader) { }

  ngOnInit(): void {
  }

}
