import { Component } from '@angular/core';

@Component({
  selector: 'app-shared-libraries',
  templateUrl: './shared-libraries.component.html',
  styleUrls: ['./shared-libraries.component.scss']
})
export class SharedLibrariesComponent {

  codeBranch = `git checkout -b "feature/exercise-3"`;

  codeCreateLibrary = `nx g lib wallet-lib
  
// Use the angular schematics to create a new library`;

  codeUpdateTsConfig = `"wallet-lib": [
  "projects/wallet-lib/src/public-api"
]`;

   codeWebpackConfig = `  // shell/webpack.config.js
  // Note the sharedMappings at the end of this configuration
   
   const {
    share,
    withModuleFederationPlugin,
  } = require("@angular-architects/module-federation/webpack");
  module.exports = withModuleFederationPlugin({
    shared: share({
    "@angular/core": {
      singleton: true,
        strictVersion: true,
        requiredVersion: "auto",
    },
    "@angular/common": {
      singleton: true,
        strictVersion: true,
        requiredVersion: "auto",
    },
    "@angular/router": {
      singleton: true,
        strictVersion: true,
        requiredVersion: "auto",
    },
    "@angular/material": {
      singleton: true,
        strictVersion: true,
        requiredVersion: "auto",
    },
    }),
    sharedMappings: ['wallet-lib'],
  });`;

  codeWebpackMFE1 = ` // mfe1/webpack.config.js
  
  const {
    share,
    withModuleFederationPlugin,
  } = require("@angular-architects/module-federation/webpack");
  module.exports = withModuleFederationPlugin({
    name: "mfe1",
    exposes: {
      "./Module": "./projects/mfe1/src/app/cryptos/cryptos.module.ts",
    },
    shared: share({
      "@angular/core": {
        singleton: true,
        strictVersion: true,
        requiredVersion: "auto",
      },
      "@angular/common": {
        singleton: true,
        strictVersion: true,
        requiredVersion: "auto",
      },
      "@angular/router": {
        singleton: true,
        strictVersion: true,
        requiredVersion: "auto",
      },
      "@angular/material": {
        singleton: true,
        strictVersion: true,
        requiredVersion: "auto",
      },
    }),
    sharedMappings: ['wallet-lib'],
  });
  `;

  codeWalletLibService = `// wallet-lib/src/lib/wallet-lib.service.ts

  import { Injectable } from "@angular/core";
  @Injectable({
    providedIn: 'root',
  })
  export class WalletLibService {
    private balanceAmount = 1000;
    public get balance(): number {
    return this.balanceAmount;
    }
    constructor() {}
    public purchase(purchaseAmount: number): void {
    this.balanceAmount = this.balanceAmount - purchaseAmount;
    }
  }
`;

  codeHomeComponent = `// shell/src/app/home/home.component.ts

  import { Component } from '@angular/core';
  import { WalletLibService } from 'wallet-lib';
  @Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
  })
  export class HomeComponent {
    balance = 0;
    constructor(private walletService: WalletLibService) {
    this.balance = this.walletService.balance;
    }
  }
`;

  codeHomeHtml = `// shell/src/app/home/home.component.html

  <h1>Welcome!</h1>
  <h1>Wallet Balance: {{ balance | currency}}</h1>`;

  codeCryptosSearch = ` // cryptos-search.component.ts

  import { Component } from '@angular/core';
  import { WalletLibService } from 'wallet-lib';

  @Component({
    selector: 'app-cryptos-search',
    templateUrl: './cryptos-search.component.html',
  })
  export class CryptosSearchComponent {
    active = false;
    balance: number;
    constructor(private walletService: WalletLibService) {
      this.balance = this.walletService.balance;
    }
    search(): void {
      this.active = true;
    }
    reset(): void {
      this.active = false;
    }
    purchase(): void {
      this.walletService.purchase(100);
      this.balance = this.walletService.balance;
  
    }
  }
`;

  codeCryptosSearchAdds = ` <p>
  Available Balance: {{ balance | currency }}
  </p>

  <button *ngIf='balance' (click)="purchase()" mat-button>Purchase</button>`;

  codeCryptosSearchHtml = `// cryptos-search.component.html

  <style>
  .example-card {
  max-width: 400px;
  }

  .example-header-image {
  background-image: url('https://material.angular.io/assets/img/examples/shiba1.jpg');
  background-size: cover;
  }
  </style>

  <p>
  <mat-form-field appearance="outline">
    <mat-label>Search For Coins</mat-label>
    <input matInput placeholder="Placeholder">
    <mat-icon matSuffix>sentiment_very_satisfied</mat-icon>
    <mat-hint>You'll probably lose money</mat-hint>
  </mat-form-field>
  </p>
  <p>
  <button (click)="search()" mat-button>Search</button>
  </p>

  <mat-card *ngIf="active" class="example-card">
  <mat-card-header>
    <div mat-card-avatar class="example-header-image"></div>
    <mat-card-title>ng-dogecoin</mat-card-title>
    <mat-card-subtitle>Meme Coin</mat-card-subtitle>
  </mat-card-header>
  <img mat-card-image src="https://material.angular.io/assets/img/examples/shiba2.jpg" alt="Photo of a Shiba Inu">
  <mat-card-content>
    <p>
      Ready to take your investments <em>to the moon?</em> Look no further than this coin guaranteed to increase in value by 10,000% by the end of the week.
    </p>
    <p>
      Available Balance: {{ balance | currency }}
    </p>
  </mat-card-content>
  <mat-card-actions>
    <button *ngIf='balance' (click)="purchase()" mat-button>Purchase</button>
    <button (click)="reset()" mat-button>Watch</button>
  </mat-card-actions>
  </mat-card>

`;

codeCommitEndOfExercise = `git add *
git commit -m "feat: working shared libs"
  `;

}
