import { Component } from '@angular/core';

@Component({
  selector: 'app-nx-federation',
  templateUrl: './nx-federation.component.html',
  styleUrls: ['./nx-federation.component.scss']
})
export class NxFederationComponent {

  nx1 = `npm add --global nx@latest`;

  nx2 = `nx g @nx/angular:host shell --directory=apps/shell --remotes=market`;

  nx3 = `npm install @angular/material @angular/cdk`;

  nx4 = `nx g @nx/angular:component home --directory="/apps/shell/src/app/home" --inlineTemplate=true --nameAndDirectoryFormat="as-provided"`;

  nx5 = `nx serve shell --devRemotes=market`;

  nx6 = `nx g @nx/angular:component not-found --directory="/apps/shell/src/app/not-found" --inlineTemplate=true --nameAndDirectoryFormat="as-provided"`;

  nx7 = 
  `  <h1>Market Closed</h1>

  <p>Please try back in a few minutes</p>`;

  nx8 = `@import '~@angular/material/prebuilt-themes/purple-green.css';`;

  nx9 = `// app.config.ts
  
  import { ApplicationConfig } from '@angular/core';
  import { provideRouter } from '@angular/router';
  import { appRoutes } from './app.routes';
  import { provideAnimations } from '@angular/platform-browser/animations';

  export const appConfig: ApplicationConfig = {
    providers: [provideRouter(appRoutes), provideAnimations()],
  };
  `;

  nx10 = `  // index.html
  
    <link rel="preconnect" href="https://fonts.gstatic.com">
    <link href="https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">
  </head>
  <body class="mat-typography" style="margin: 0px">
  `;

  nx11 = `  // app.component.scss
  
  .content-wrapper {
    height: calc(100vh - 64px) !important;
   }
   
   
   .content-spacer {
    padding: 25px !important;
   }
   
   
   .menu-spacer {
    padding-left: 5px;
   }
   
   
   mat-sidenav {
    min-width: 175px !important;
   }
   
   
   .coin {
    margin-top: 18px;
   }
   
  `;

  nx12 = `nx g @nx/angular:component cryptos --directory="/apps/market/src/app/cryptos" --inlineTemplate=true --nameAndDirectoryFormat="as-provided"`;

  nx13 = `  // cryptos.component.ts
  
    import { Component } from '@angular/core';
  import { CommonModule } from '@angular/common';
  import { CryptosComponent } from '../cryptos/cryptos.component';


  @Component({
    standalone: true,
    imports: [CommonModule, CryptosComponent],
    selector: 'mfe-workshop-2024-market-entry',
    template: \`<mfe-workshop-2024-cryptos></mfe-workshop-2024-cryptos>\`,
    })
    export class RemoteEntryComponent {}
    `;


    nx14 = `nx serve shell --devRemotes=market`;

    nx15 = `  // cryptos.component.ts
    
    import { Component } from '@angular/core';
    import { CommonModule } from '@angular/common';
    import { MatButtonModule } from '@angular/material/button';
    import { MatCardModule } from '@angular/material/card';
    import { MatIconModule } from '@angular/material/icon';
    import { MatInputModule } from '@angular/material/input';


    @Component({
    selector: 'mfe-workshop-2024-cryptos',
    standalone: true,
    imports: [CommonModule, MatButtonModule, MatCardModule, MatIconModule, MatInputModule],
    template: \`<style>
    .example-card {
    max-width: 400px;
    }


    .example-header-image {
    background-image: url('https://material.angular.io/assets/img/examples/shiba1.jpg');
    background-size: cover;
    }
    </style>


    <p>
    <mat-form-field appearance="outline">
      <mat-label>Search For Coins</mat-label>
      <input matInput placeholder="Placeholder">
      <mat-icon matSuffix>sentiment_very_satisfied</mat-icon>
      <mat-hint>You'll probably lose money</mat-hint>
    </mat-form-field>
    </p>
    <p>
    <button (click)="search()" mat-button>Search</button>
    </p>


    <mat-card *ngIf="active" class="example-card">
    <mat-card-header>
      <div mat-card-avatar class="example-header-image"></div>
      <mat-card-title>ng-dogecoin</mat-card-title>
      <mat-card-subtitle>Meme Coin</mat-card-subtitle>
    </mat-card-header>
    <img mat-card-image src="https://material.angular.io/assets/img/examples/shiba2.jpg" alt="Photo of a Shiba Inu">
    <mat-card-content>
      <p>
        Ready to take your investments <em>to the moon?</em> Look no further than this coin guaranteed to increase in value by 10,000% by the end of the week.
      </p>
    </mat-card-content>
    <mat-card-actions>
      <button (click)="purchase()" mat-button>Purchase</button>
      <button (click)="reset()" mat-button>Watch</button>
    </mat-card-actions>
    </mat-card>


    \`,
    styleUrl: './cryptos.component.css',
    })
    export class CryptosComponent {


    active = false;
    search(): void {
      this.active = true;
    }


    reset(): void {
      this.active = false;
    }


    purchase(): void {
      console.log('Purchased');
    }
  }

  `;

  nx16 = `create the library at libs/shared/wallet and name the library <em>wallet</em>
  uncheck standalone 
  generate the library and then close vs code to pick up changes for the library mappings
  `;

  nx17 = `import { Injectable } from '@angular/core';


  @Injectable({
   providedIn: 'root',
  })
  export class WalletService {
   private balanceAmount = 1000;
   public get balance(): number {
     return this.balanceAmount;
   }
  
  
   public deposit(depositAmount: number): void {
     this.balanceAmount = this.balanceAmount + depositAmount;
   }
  
  
   public purchase(purchaseAmount: number): void {
     this.balanceAmount = this.balanceAmount - purchaseAmount;
   }
  }
  `;

  nx18 = `  // app.component.ts
  
  import { Component } from '@angular/core';
  import { CommonModule } from '@angular/common';
  import { WalletService } from '@mfe-workshop-2024/wallet';

  @Component({
    selector: 'mfe-workshop-2024-home',
    standalone: true,
    imports: [CommonModule],
    template: \`<h1>Welcome! {{ balance | currency }}</h1>\`,
    styleUrl: './home.component.css',
  })
  export class HomeComponent {
    balance = 0;
    constructor(private walletService: WalletService) {
      this.balance = walletService.balance;
    }
  }

  `;

  nx19 = `  // cryptos.component.ts
  
  import { Component } from '@angular/core';
  import { CommonModule } from '@angular/common';
  import { MatButtonModule } from '@angular/material/button';
  import { MatCardModule } from '@angular/material/card';
  import { MatIconModule } from '@angular/material/icon';
  import { MatInputModule } from '@angular/material/input';
  import { WalletService } from '@mfe-workshop-2024/wallet';
  
  @Component({
    selector: 'mfe-workshop-2024-cryptos',
    standalone: true,
    imports: [CommonModule, MatButtonModule, MatCardModule, MatIconModule, MatInputModule],
    template: \`<style>
    .example-card {
    max-width: 400px;
  }
  
  .example-header-image {
    background-image: url('https://material.angular.io/assets/img/examples/shiba1.jpg');
    background-size: cover;
  }
  </style>
  
  <p>
    <mat-form-field appearance="outline">
      <mat-label>Search For Coins</mat-label>
      <input matInput placeholder="Placeholder">
      <mat-icon matSuffix>sentiment_very_satisfied</mat-icon>
      <mat-hint>You'll probably lose money</mat-hint>
    </mat-form-field>
  </p>
  <p>
    <button (click)="search()" mat-button>Search</button>
  </p>
  
  <mat-card *ngIf="active" class="example-card">
    <mat-card-header>
      <div mat-card-avatar class="example-header-image"></div>
      <mat-card-title>ng-dogecoin</mat-card-title>
      <mat-card-subtitle>Meme Coin</mat-card-subtitle>
    </mat-card-header>
    <img mat-card-image src="https://material.angular.io/assets/img/examples/shiba2.jpg" alt="Photo of a Shiba Inu">
    <mat-card-content>
      <p>
        Ready to take your investments <em>to the moon?</em> Look no further than this coin guaranteed to increase in value by 10,000% by the end of the week.
      </p>
      <p>
        Available Balance: {{ balance | currency }}
      </p>
    </mat-card-content>
    <mat-card-actions>
    <button *ngIf='balance' (click)="purchase()" mat-button>Purchase</button>
      <button (click)="reset()" mat-button>Watch</button>
    </mat-card-actions>
  </mat-card>
  
  \`,
    styleUrl: './cryptos.component.css',
  })
  export class CryptosComponent {
    active = false;
    balance: number;
    constructor(private walletService: WalletService) {
      this.balance = this.walletService.balance;
    }
    search(): void {
      this.active = true;
    }
    reset(): void {
      this.active = false;
    }
    purchase(): void {
      this.walletService.purchase(100);
      this.balance = this.walletService.balance;
  
  }
  }
  
  `;


  nx20 = `nx g @nx/angular:remote reports --directory=apps/reports --host=shell`;

  nx21 = `  <h1>Reports Work</h1>`;

  nx22 = `.widget { height: 200px; width: 400px; }`;

  nx23 = `
  <img src="http://localhost:4200/assets/graph1.png" class="widget"/> 
  <img src="http://localhost:4200/assets/graph2.png" class="widget"/>
  <img src="http://localhost:4200/assets/graph3.png" class="widget"/>
  <img src="http://localhost:4200/assets/graph4.png" class="widget"/>`;

  nx24 = ` // nx-welcome.component.ts
  
  import { Component, ViewEncapsulation } from '@angular/core';
  import { CommonModule } from '@angular/common';
  import { Chart1Component } from '../chart-1/chart-1.component';
  import { Chart2Component } from '../chart-2/chart-2.component';
  import { Chart3Component } from '../chart-3/chart-3.component';
  import { Chart4Component } from '../chart-4/chart-4.component';


  @Component({
  selector: 'mfe-workshop-2024-nx-welcome',
  standalone: true,
  imports: [CommonModule, Chart1Component, Chart2Component, Chart3Component, Chart4Component],
  template: \`<h1>Reports Work</h1>
  <mfe-workshop-2024-chart-1></mfe-workshop-2024-chart-1>
  <mfe-workshop-2024-chart-2></mfe-workshop-2024-chart-2>
  <mfe-workshop-2024-chart-3></mfe-workshop-2024-chart-3>
  <mfe-workshop-2024-chart-4></mfe-workshop-2024-chart-4>
  \`,
  styles: [],
  encapsulation: ViewEncapsulation.None,
  })
  export class NxWelcomeComponent {}
  `;

  nx25 = `nx serve reports`;

  nx26 = `  // module-federation.config.ts
  
  import { ModuleFederationConfig } from '@nx/webpack';


  const config: ModuleFederationConfig = {
  name: 'reports',
  exposes: {
    './Routes': 'apps/reports/src/app/remote-entry/entry.routes.ts',
    './Chart1': 'apps/reports/src/app/chart-1/chart-1.component.ts',
    './Chart2': 'apps/reports/src/app/chart-2/chart-2.component.ts',
    './Chart3': 'apps/reports/src/app/chart-3/chart-3.component.ts',
    './Chart4': 'apps/reports/src/app/chart-4/chart-4.component.ts',
  },
  };

  export default config;
  `;

  nx27 = `npm i @angular-architects/module-federation@17.0.8`;

  nx28 = `
    import { LoadRemoteModuleOptions } from '@angular-architects/module-federation';
    export type PluginOptions = LoadRemoteModuleOptions & {
        displayName: string;
        componentName: string;
    };
  `;

  nx29 = `
  import {
    Component,
    Input,
    OnChanges,
    ViewChild,
    ViewContainerRef,
   } from '@angular/core';
   import { CommonModule } from '@angular/common';
   import { loadRemoteModule } from '@angular-architects/module-federation';
   import { PluginOptions } from './plugin';
   
   
   @Component({
    selector: 'mfe-workshop-2024-plugin-proxy',
    standalone: true,
    imports: [CommonModule],
    template: \`<ng-container #placeHolder></ng-container>\`,
    styles: \`\`,
   })
   export class PluginProxyComponent implements OnChanges {
    @ViewChild('placeHolder', { read: ViewContainerRef, static: true })
    viewContainer!: ViewContainerRef;
    @Input() options!: PluginOptions;
    async ngOnChanges() {
      this.viewContainer.clear();
      const Component = await loadRemoteModule(this.options).then(
        (m) => m[this.options.componentName]
      );
      this.viewContainer.createComponent(Component);
    }
   }
   
  
  `;

  nx30 = `import { Injectable } from '@angular/core';
  import { PluginOptions } from './plugins/plugin';
  @Injectable({ providedIn: 'root' })
  export class LookupService {
   lookup(): Promise<PluginOptions[]> {
       return Promise.resolve([
           {
               type: 'module',
               remoteEntry: 'http://localhost:4202/remoteEntry.mjs',
               exposedModule: './Chart1',
               displayName: 'Pricing Trends',
               componentName: 'Chart1Component'
           },
           {
             type: 'module',
             remoteEntry: 'http://localhost:4202/remoteEntry.mjs',
             exposedModule: './Chart2',
             displayName: 'Watch List',
             componentName: 'Chart2Component'
         },
         {
           type: 'module',
           remoteEntry: 'http://localhost:4202/remoteEntry.mjs',
           exposedModule: './Chart3',
           displayName: 'Current Value',
           componentName: 'Chart3Component'
       },
       {
         type: 'module',
         remoteEntry: 'http://localhost:4202/remoteEntry.mjs',
         exposedModule: './Chart4',
         displayName: 'Bitcoin Tracker',
         componentName: 'Chart4Component'
     }
       ] as PluginOptions[]);
   }
  }
  `;

  nx31 = `nx serve shell --devRemotes=market,reports`;
}
